<!-- @format -->

<template>
	<LayoutWide>
		<div>
			<div>
				<sub-navbar page="Reminder" />
			</div>
		</div>
		<div v-if="loading">...Loading</div>
		<div v-if="!loading">
			<div>
				<p>Beginning Date: {{ getDates().bd }}</p>
			</div>
			<div class="margin-bottom-20">
				<p>Ending Date: {{ getDates().ed }}</p>
			</div>
			<div>
				<section>
					<div>
						<b>Is invoice Paid:</b><br />
						<b-radio v-model="isInvoicePaid" name="paid" native-value="false">
							Not Paid
						</b-radio>
						<b-radio v-model="isInvoicePaid" name="paid" native-value="true">
							Paid
						</b-radio>
					</div>
				</section>
				<section>
					<div>
						<b>Is invoice reminded:</b><br />
						<b-radio
							v-model="isInvoiceReminded"
							name="reminder"
							native-value="true"
						>
							Invoice is reminded
						</b-radio>
					</div>
				</section>
				<section>
					<div>
						<b>Is invoice sent to inkasso:</b><br />
						<b-radio
							v-model="isSentToInkasso"
							name="inkasso"
							native-value="false"
						>
							Not sent to inkasso
						</b-radio>
						<b-radio
							v-model="isSentToInkasso"
							name="inkasso"
							native-value="true"
						>
							Sent to inkasso
						</b-radio>
					</div>
				</section>
			</div>
			<div>
				<b-button class="is-success margin-top-10" @click="refreshTable">
					Refresh
				</b-button>
			</div>
			<div class="margin-bottom-30 margin-top-30">
				<ve-table
					:rows="rowsList"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:sort-options="sortOptions"
				>
				</ve-table>
				<div v-if="!!isModalVisible">
					<ModalReminderFrame />
				</div>
			</div>
		</div>
	</LayoutWide>
</template>

<script>
import {
	ref,
	reactive,
	onMounted,
	// computed,
	watch,
} from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetInvoicesQuery from '@/_srcv2/pages/reminder/view/graphql/GetInvoicesQuery.graphql'
import dateFormat from 'dateformat'
import ModalReminderFrame from '@/_srcv2/pages/_reports/get-data-and-report/reminder/reminder-get-data/ModalReminderFrame.vue'
import useProcessReportStore from '@/_srcv2/pages/_reports/create-report/useProcessReportStore.js'
// import storeReminder from '@/_srcv2/pages/reminder/_shared/useReminderState.js'

export default {
	name: 'ViewReminder',
	components: {
		SubNavbar,
		ModalReminderFrame,
	},
	setup() {
		const subtractYears = (numOfYears, date = new Date()) => {
			date.setFullYear(date.getFullYear() - numOfYears)
			return date
		}
		const getDates = (now = new Date()) => {
			const date = dateFormat(now, 'isoDate')
			const endingDate = date
			const tomorrow = now
			console.log('ending date: ', endingDate, 'tomorrow', tomorrow)
			tomorrow.setDate(tomorrow.getDate() + 1)
			const beginningDate = dateFormat(subtractYears(1, tomorrow), 'isoDate')
			console.log('beginningDate', beginningDate)
			return {
				ed: endingDate,
				bd: beginningDate,
			}
		}
		// console.log(getDates())
		const isInvoicePaid = ref('false')
		const isInvoiceReminded = ref('true')
		const isSentToInkasso = ref('false')
		onMounted(() => {
			// console.log('onMounted is fired')
			isInvoicePaid.value = 'false'
			isInvoiceReminded.value = 'true'
			isSentToInkasso.value = 'false'
			refreshTable()
		})
		const refreshTable = () => {
			refetch().then(() => {
				// console.log('refreshTable')
			})
		}
		const sortOptions = {
			enabled: true,
			multipleColumns: true,
			initialSortBy: [
				{ field: 'reminderDueDate', type: 'asc' },
				{ field: 'invoiceNo', type: 'asc' },
			],
		}
		// * Apollo Query -------------------------------------------------------------------------
		const variablesForQuery = reactive({
			our_company: Store.getters.getUserCurrentCompany,
			is_invoice_paid: 'false',
			is_invoice_reminded: true,
			is_sent_in_the_bailiffs: false,
			bd: getDates().bd,
			ed: getDates().ed,
		})
		const refreshCounter = ref(0)
		watch(refreshCounter, () => {
			variablesForQuery.is_invoice_paid = 'true'
		})
		// *----isInvoicePaid---------------------------------------------------------------------
		watch(isInvoicePaid, (selection) => {
			// console.log('**** First Watch selection', selection, typeof selection)
			if (selection === 'true') {
				variablesForQuery.is_invoice_paid = 'true'
			} else if (selection === 'false') {
				variablesForQuery.is_invoice_paid = 'false'
			} else {
				delete variablesForQuery.is_invoice_paid
				refreshCounter.value += 1
			}
		})
		// *----isInvoiceReminded-------------------------------------------------------------------------------
		watch(isInvoiceReminded, (selection) => {
			// console.log('**** First Watch selection', selection, typeof selection)
			if (selection === 'true') {
				variablesForQuery.is_invoice_reminded = true
			} else if (selection === 'false') {
				variablesForQuery.is_invoice_reminded = false
			} else {
				delete variablesForQuery.is_invoice_reminded
			}
		})
		// *----isSentToInkasso------------------------------------------------------------------------------------
		watch(isSentToInkasso, (selection) => {
			// console.log('**** First Watch selection', selection, typeof selection)
			if (selection === 'true') {
				variablesForQuery.is_sent_in_the_bailiffs = true
			} else if (selection === 'false') {
				variablesForQuery.is_sent_in_the_bailiffs = false
			} else {
				delete variablesForQuery.is_sent_in_the_bailiffs
			}
		})
		// *----------------------------------------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, refetch, onResult } = useQuery(
			GetInvoicesQuery,
			() => variablesForQuery,
			options,
		)
		// *----------------------------------------------------------------------------------------
		const columnsList = ref([
			{
				label: 'Invoice No',
				field: 'invoiceNo',
				width: '98px',
			},
			{
				label: 'Customer',
				field: 'customer',
				width: '355px',
			},
			{
				label: 'Invoice Date',
				field: 'invoiceDate',
				width: '108px',
			},
			{
				label: 'Invoice Due Date',
				field: 'invoiceDueDate',
				width: '108px',
			},
			{
				label: 'Reminder No',
				field: 'reminderNo',
				width: '80px',
			},
			{
				label: 'Reminder Date',
				field: 'reminderDate',
				width: '108px',
			},
			{
				label: 'Reminder Due Date',
				field: 'reminderDueDate',
				width: '118px',
			},
			{
				label: 'Payment Date',
				field: 'paymentDate',
				width: '108px',
			},
		])
		const rowsList = ref([])
		onResult((result) => {
			console.log('onResult', result?.data?.document_transactions)
			rowsList.value = result?.data?.document_transactions?.map((item) => {
				return {
					invoiceNo: item.invoice_number,
					customer: `${item.customer.customer_title} - ${item.customer.customer_nickname}`,
					invoiceDate: item.invoice_date,
					invoiceDueDate: item.invoice_due_date,
					reminderNo: item?.reminder_lines?.reminder_header?.reminder_id,
					reminderDate: item?.reminder_lines?.reminder_header?.reminder_date,
					reminderDueDate:
						item?.reminder_lines?.reminder_header?.reminder_due_date,
					paymentDate:
						item?.invoice_payments_aggregate?.aggregate?.max?.payment_date,
					ourCompany: item.our_company,
					customerId: item.customer.customer_id,
				}
			})
		})
		// ---------------------------------------------------------------------------------------
		const getPdfReport = (row) => {
			setVariables(row).then(() => (isModalVisible.value = true))
		}
		const onCellClick = (params) => {
			if (params.column.field === 'reminderNo') {
				// console.log(params.row)
				getPdfReport(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			// console.log(params.row)
			getPdfReport(params.row)
		}
		// *--------------------------------------------------------------------------------
		// const { reportOurCompany, reportOrderNumber } = storeReminder()
		// const storeDataAndGetReport = (row) => {
		// 	getPdfReport(row)
		// }
		const {
			reportDocumentType,
			reportDocumentNumber,
			reportDocumentOurCompany,
			isSelectedDocumentLocked,
			isModalVisible,
			reportCustomer,
			reportDocumentDate,
			reportDocumentStatus,
			isSelectedDocumentReported,
		} = useProcessReportStore()
		const setVariables = (row) => {
			return new Promise((resolve, reject) => {
				if (row !== null || undefined) {
					reportDocumentType.value = 'reminder'
					reportDocumentNumber.value = row.reminderNo
					reportDocumentOurCompany.value = row.ourCompany
					isSelectedDocumentLocked.value = true
					isSelectedDocumentReported.value = true
					reportCustomer.value = `${row.customerId} - ${row.customer}`
					reportDocumentDate.value = row.reminderDate
					reportDocumentStatus.value = 'reminded'
					resolve('ok')
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		// * ------------------------------------------------------------------------------
		return {
			isInvoicePaid,
			isSentToInkasso,
			isInvoiceReminded,
			refreshTable,
			sortOptions,
			loading,
			columnsList,
			rowsList,
			onCellClick,
			onRowDoubleClick,
			isModalVisible,
			getDates,
		}
	},
}
</script>

<style lang="scss" scoped></style>
